import classNames from 'classnames';
import { useSwiperSlide } from 'swiper/react';

import { AuctionBadge } from 'components/auctions/Listings/AuctionBadge';
import { ViewBidSignUp } from 'components/auctions/ViewBidSignUp';
import { CloudinaryImage } from 'components/common/CloudinaryImage';
import { Link } from 'components/common/Link';
import { ListingTag } from 'components/common/ListingTag';
import { useTheme } from 'context/theme';
import { useViewport } from 'context/viewport';
import { getTitleFromListing } from 'lib/getTitleFromListing';
import { pageRoutes } from 'lib/routes';
import styles from 'styles/ListingCard.module.scss';
import { Listing } from 'types/listing';
import { formatPrice, getListingStrikethroughPrice, getListingTitleTruncated } from 'utils/text-helpers';

export interface ListingCardProps {
  listing: Listing;
  className?: string;
  isAuth: boolean;
}

export const ListingCard: React.FC<ListingCardProps> = ({ listing, className, isAuth }) => {
  const { isMobileSmall, isMobile } = useViewport();
  const { isDark, isDefault } = useTheme();
  const swiperSlide = useSwiperSlide();
  const collapsedTitle = getListingTitleTruncated(listing, { truncate: 100 });
  const formattedPrice = formatPrice(listing.priceCents);
  const title = getTitleFromListing(listing);
  const isAuction = listing.activePricingModel === 'AUCTION';

  const showCard = swiperSlide == null || swiperSlide.isVisible || isMobileSmall;

  return (
    <Link
      passHref
      href={pageRoutes.fullListingUrl({
        brandDisplayName: listing.model.brand.displayName,
        modelDisplayName: listing.model.displayName,
        referenceNumber: listing.model.referenceNumber,
        listingId: listing.id
      })}
      className={classNames(
        'rounded-2 d-flex flex-column align-items-center',
        styles['opacity-transition'],
        showCard ? 'opacity-1' : 'opacity-0',
        className
      )}
    >
      <div
        className={classNames(
          'd-flex flex-column justify-content-around border rounded-2 border-muted overflow-hidden w-100',
          { 'shadow-on-hover': !isMobileSmall, 'border-muted': isDefault, 'border-light20': isDark }
        )}
      >
        <div className='position-relative'>
          {listing.activePricingModel === 'AUCTION' && <AuctionBadge />}
          {!!(listing?.tags?.length > 0) && listing.activePricingModel === 'FIXED_PRICE' && (
            <ListingTag tags={listing.tags} className='mt-2 ms-2' />
          )}
          <CloudinaryImage
            responsive
            alt={`${title} Listing Image`}
            sizes='40vw'
            src={listing?.images?.find(i => i.type === 'FRONT')?.image.optimizedUrl || 'model_placeholder.png'}
          />
        </div>
      </div>
      <div
        className={`fs-12px px-1 riforma-regular mt-2 align-self-start clamp-lines-2 ${isDark ? 'text-light' : 'text-primary'}`}
      >
        {collapsedTitle}
      </div>
      <div className={`fs-12px px-1 mt-2 align-self-start ${isDark ? 'text-turquoise' : 'text-success'}`}>
        {(isAuction && isAuth) || !isAuction ? (
          <>
            {formattedPrice}
            <span className='text-secondary text-decoration-line-through px-2'>
              {getListingStrikethroughPrice(listing)}
            </span>
          </>
        ) : (
          <>
            {isMobile && <div style={{ marginTop: 2 }} />}
            <ViewBidSignUp isSmall isTextVisible={!isMobile} color={isDark ? 'turquoise' : 'success'} />
          </>
        )}
      </div>
    </Link>
  );
};
